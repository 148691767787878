import { template as template_9889982529b04035b78de7c8b3a8b395 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_9889982529b04035b78de7c8b3a8b395(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
