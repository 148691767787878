import { template as template_ea9dea1172304bdf8bab47f05c85ff95 } from "@ember/template-compiler";
const WelcomeHeader = template_ea9dea1172304bdf8bab47f05c85ff95(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
