import { template as template_5dac1ac369be4cafac93359b8efeb59c } from "@ember/template-compiler";
const SidebarSectionMessage = template_5dac1ac369be4cafac93359b8efeb59c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
