import { template as template_9fd210e5cc304c38bf7be0140be51a48 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9fd210e5cc304c38bf7be0140be51a48(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
